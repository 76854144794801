export default {
  details: {
    title: "Felix Costa",
    tagLine: "Blog & Profile",
    description: "Personal portfolio of Felix Costa",
    url: "https://www.fxcosta.com",
  },
  assets: {
    avatar: "/images/common/fxcosta2.jpg",
    favicon: "/images/common/favicon.png",
  },
  socialLinks: {
    twitter: "@fxcosta",
  },
};
